import * as React from 'react'
import Layout from '../components/Layout'
import { Button } from '../components/Button'
import {
  Text,
  Cards,
  Container,
  SplitContent,
  SplitSection,
  SplitFigure,
  ReadMore
} from '../components/styles'
import styled from 'styled-components'
import { xPad, yPad } from '../utils/theme'
import Form from '../components/Form'
import Card from '../components/Card'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Testimonials from '../components/Testimonials'
import { testimonials } from './testimonials'
import Arrow from '../components/Arrow'
import CTA from '../components/CTA'
import { Flex, Box } from 'grid-styled'

const HomeHeader = styled.header`
  height: 80vh;
  padding-top: 7rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #fff;
  position: relative;

  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 8rem;
    height: 650px;
    > div > button {
      display: none;
    }
    > div:nth-child(2) {
      margin-top: 4rem;
    }
  }
  @media (max-width: 480px) {
    > div:nth-child(2) {
      margin-top: 10rem;
    }
  }

  figure {
    background-image: linear-gradient(-45deg, #fe9124 0%, #e71506 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: skewY(-8deg);
    transform-origin: left top 0px;

    .gatsby-image-outer-wrapper {
      position: absolute !important;
      width: 85vw;
      bottom: -50px;
      right: 0;
    }
  }

  h2 {
    color: #fff;
    font-weight: 500;
    max-width: 700px;

    em {
      text-decoration: underline;
      font-weight: 700;
      font-style: normal;
    }
  }

  p {
    font-weight: 500;
    line-height: 150%;
    padding-right: 2rem;
    max-width: 500px;
    margin: 1.5rem 0 2rem;
    opacity: 0.95;

    @media (max-width: 900px) {
      padding-right: 0;
    }
  }

  @media (min-width: 600px) {
    padding-top: 9rem;
    /* padding-left: 5rem; */
  }

  @media (min-width: 940px) {
    /* padding-left: 10rem; */
  }
`

const FormContainer = styled.div`
  width: 390px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1);
  > div {
    padding: 1.5rem 2rem !important;
    border-top: 0;
  }
  h2 {
    color: #333 !important;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 900px) {
    margin-top: 2rem;
  }

  @media (max-width: 480px) {
    width: 105%;
  }
`

const Wins = styled.section`
  border-radius: 8px;
  background-color: #f1f8fe;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  ${xPad};
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-top: 3rem;
  text-align: center;

  div {
    padding: 1rem;
    @media (min-width: 960px) {
      max-width: 20%;
    }
    @media (min-width: 640px) {
      max-width: 25%;
    }
    @media (min-width: 480px) {
      max-width: 50%;
    }
  }

  i {
    font-weight: 800;
    font-size: 3rem;
    font-style: normal;
    color: #171717;
    letter-spacing: 0;

    &:before {
      content: '▲';
      color: #73cd10;
      display: inline;
      font-size: 1.5rem;
      padding-right: 0.5rem;
    }
  }

  p {
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
  }
`

const ServicesContainer = styled(Container)`
  > div {
    margin-bottom: 6rem;
  }
`
const CardsContainer = styled(Container)`
  > div {
    justify-content: space-around;
    @media (min-width: 940px) {
      > div {
        width: 31%;
      }
    }
  }
`

/* prettier-ignore */
interface IProps {
  data: {
    graphTrend: {
      childImageSharp: {
        fluid: object
      }
    };
  }
}

export default ({ data }: IProps) => {
  return (
    <Layout header="light">
      <Helmet>
        <title>Turboclicks - Brisbane's Digital Growth Strategists</title>
        <meta
          name="description"
          content="Learn to double your business sales with strategic digital growth marketing: SEO, Social, Google Ads and Web Design."
        />
      </Helmet>
      <HomeHeader>
        <figure>
          <Img fluid={data.graphTrend.childImageSharp.fluid} />
        </figure>
        <div>
          <h2>
            Grow my business with modern <em>digital marketing</em>
          </h2>
          <Text size="large">
            Want to drive traffic, increase sales, and boost revenue. Find out
            how we can turbocharge your online presence.
          </Text>
          <Button>Book my free strategy session</Button>
        </div>
        <FormContainer>
          <Form title="Get a free consultation" showMessage={false} />
        </FormContainer>
      </HomeHeader>

      <Container padY={true} style={{ textAlign: 'center' }}>
        <h1 style={{ marginBottom: '1rem' }}>Brisbane Digital Marketing</h1>
        <h2>Latest client wins in the last 30 days</h2>
        <Wins>
          <div>
            <i>22%</i>
            <p>
              Bounce rate <br />improvement
            </p>
          </div>
          <div>
            <i>80+</i>
            <p>
              Keyword rankings on <br />page one
            </p>
          </div>
          <div>
            <i>116%</i>
            <p>
              Increase in new <br />enquiries
            </p>
          </div>
          <div>
            <i>20x</i>
            <p>
              Social media page <br />engagement growth
            </p>
          </div>
        </Wins>
      </Container>

      <CardsContainer>
        <Cards>
          <Card
            color="primary"
            shadow={true}
            title="Results driven"
            content="At the end of the day, it's the results that speak. To this end we emobdy transparency and communication. Everything we do is oriented towards producing more leads, sales and profits in a sustainable and positive way."
          />

          <Card
            color="tertiary"
            shadow={true}
            title="Experienced"
            content="In our industry, the rules are always changing. We value our ability to adapt. That said, we've seen alot of change already and over the past decade have built up our skills where it matters."
          />

          <Card
            color="purple"
            shadow={true}
            title="Evolved practices"
            content="We align our efforts with your entire marketing strategy to holistically grow your business. No strategy is an island, our expertise in different areas and background allows us to produce complete and effective outcomes for your business."
          />
        </Cards>
      </CardsContainer>

      <Testimonials testimonials={testimonials} />

      <Box mb={5}>
        <Container full={true} padY={true}>
          <Container medium={true}>
            <h2>
              “The only way you can control your destiny is to be more flexible
              than your environment.”
            </h2>
          </Container>
        </Container>
      </Box>

      <ServicesContainer medium={true}>
        <SplitSection>
          <SplitFigure>
            <Img
              fluid={data.social.childImageSharp.fluid}
              alt="Turboclicks business woman smiling"
            />
          </SplitFigure>
          <SplitContent>
            <h2>Search engine optimisation</h2>
            <p>
              Dominate Google's first page and get up to 70 percent of all
              search traffic with search engine optimisation.
            </p>
            <ReadMore to="/services/search-engine-optimisation">
              Discover the power of SEO <Arrow rotate="-90deg" />
            </ReadMore>
          </SplitContent>
        </SplitSection>

        <SplitSection alternate={true}>
          <SplitFigure>
            <Img
              fluid={data.social.childImageSharp.fluid}
              alt="Turboclicks happy partners without contracts"
            />
          </SplitFigure>
          <SplitContent>
            <h2>Social media marketing</h2>
            <p>
              Take advantage of the largest database of attention the world has
              even known with organic and paid social media marketing.
            </p>
            <ReadMore to="/services/social-media-marketing">
              Upgrade your social strategy <Arrow rotate="-90deg" />
            </ReadMore>
          </SplitContent>
        </SplitSection>

        <SplitSection>
          <SplitFigure>
            <Img
              fluid={data.ppc.childImageSharp.fluid}
              alt="Turboclicks happy partners without contracts"
            />
          </SplitFigure>
          <SplitContent>
            <h2>Google Ads PPC Advertising</h2>
            <p>
              Get your business in front of customers who are searching for your
              services and product with Pay Per Click advertising.
            </p>
            <ReadMore to="/services/google-ppc-advertising">
              Discover the power of PPC <Arrow rotate="-90deg" />
            </ReadMore>
          </SplitContent>
        </SplitSection>

        <SplitSection alternate={true}>
          <SplitFigure>
            <Img
              fluid={data.webDev.childImageSharp.fluid}
              alt="Turboclicks happy partners without contracts"
            />
          </SplitFigure>
          <SplitContent>
            <h2>Website design &amp; development</h2>
            <p>
              Realistically, without a website your business is invisible. If
              you need a new site, a redesign or a team of experienced
              developers to provide some guidance on what's best, we're here to
              help.
            </p>
            <ReadMore to="/services/website-development-brisbane">
              Design your sales machine <Arrow rotate="-90deg" />
            </ReadMore>
          </SplitContent>
        </SplitSection>

        <SplitSection>
          <SplitFigure>
            <Img
              fluid={data.hosting.childImageSharp.fluid}
              alt="Turboclicks happy partners without contracts"
            />
          </SplitFigure>
          <SplitContent>
            <h2>Brisbane web hosting</h2>
            <p>
              Make your hosting simple, so you can stick to doing what you do
              best. Get in touch with us about our fully managed web hosting
              solutions for your business today.
            </p>
            <ReadMore to="/services/brisbane-website-hosting">
              Forget the boring bits <Arrow rotate="-90deg" />
            </ReadMore>
          </SplitContent>
        </SplitSection>
      </ServicesContainer>

      <CTA
        inverted={true}
        title="Not sure what's best for you?"
        subtitle="We’re here to help."
        button="Speak to a specialist"
      />

      <Container
        style={{ borderRadius: 4, margin: '6rem auto' }}
        bg="#f1f8fe"
        padY={true}
        medium={true}
      >
        <SplitSection style={{ marginLeft: 0 }}>
          <SplitFigure>
            <Img
              fluid={data.coast.childImageSharp.fluid}
              alt="Turboclicks business woman smiling"
            />
          </SplitFigure>
          <SplitContent>
            <h5>The Turboclicks Team</h5>
            <p>
              Turboclicks is a small local agency with a human touch. We won't
              throw you in the pipeline, we believe growth is strongest in close
              collaboration between people.
            </p>
            <ReadMore to="/contact">
              Let's talk <Arrow rotate="-90deg" />
            </ReadMore>
          </SplitContent>
        </SplitSection>
        <Flex justifyContent="center" mt={5}>
          <h3>
            Our team is made up of hard-working, fun, and passionate people. We
            love technology and business growth.
          </h3>
        </Flex>
      </Container>

      <Form />
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    graphTrend: file(relativePath: { eq: "graph-trend.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    webDev: file(relativePath: { eq: "woman-website.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    social: file(
      relativePath: { eq: "turboclicks-social-media-marketing.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    ppc: file(relativePath: { eq: "google-adwords-phone.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    hosting: file(relativePath: { eq: "server-room-hosting-turboclicks.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    coast: file(relativePath: { eq: "gold-coast.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
