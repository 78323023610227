import styled, { css } from 'styled-components'

const fieldCss = css`
  width: 100%;
  padding: 15px 1rem;
  margin-top: 1rem;
  border: 1px solid #dcdfe4;
  resize: none;
  vertical-align: top;
  outline: 0;
  color: #2e3280;
  background-color: #fff;
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);

  @media (min-width: 940px) {
    padding: 20px 1.25rem;
  }

  &::placeholder {
    color: rgba(46, 50, 128, 0.5);
  }

  &:focus {
    border-color: #0099ff;
    background-color: #fff;
  }
`

export default styled.input`
  ${fieldCss};
`

export const Textarea = styled.textarea`
  ${fieldCss};
`
