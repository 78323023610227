import * as React from 'react'
import { Box } from 'grid-styled'
import styled from 'styled-components'
import { Button } from './Button'
import Input, { Textarea } from './Input'
import { yPad, xPad } from '../utils/theme'

interface IContainerProps {
  noBorder: boolean
}

const Container = styled<IContainerProps, any>('div')`
  ${yPad};
  ${xPad};
  border-top: ${(props) => (props.noBorder ? 'none' : '1px solid #eee')};
`

const Form = styled.form`
  margin: 0 auto;
  max-width: 500px;

  h2 {
    margin-bottom: 2rem;
  }
`

const Fieldset = styled.div`
  margin: -0.375rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media (min-width: 940px) {
    margin: -0.5rem;
  }
`

interface Props {
  children?: React.ReactChild
  title?: string
  showMessage?: boolean
  id?: string
  type?: 'audit'
}

export default class extends React.Component<Props> {
  static defaultProps = {
    showMessage: true,
  }

  state = {
    buttonMessage: 'Request a free strategy session',
    email: '',
    message: '',
    name: '',
    phone: '',
    website: '',
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value,
    })
  }

  encode = (data: any) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
  }

  handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    // @ts-ignore
    fbq('track', 'Lead', {
      content_category: 'form',
      content_name: window.location.pathname,
    })

    this.setState({ buttonMessage: 'Working...' })

    fetch('/.netlify/functions/send-email/', {
      body: JSON.stringify(this.state),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error()
        }
        this.setState({ buttonMessage: 'Thank You!' })
      })
      .catch(() => {
        this.setState({ buttonMessage: 'Error - Try later!' })
      })

    event.preventDefault()
  }

  render() {
    const { type, title, showMessage, ...rest } = this.props
    const isAudit = type === 'audit'

    return (
      <Container noBorder={isAudit} {...rest}>
        <Form name="contactForm" method="post" onSubmit={this.handleSubmit}>
          <h2>{title || 'Contact us'}</h2>
          <Fieldset>
            <Input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              required={true}
              value={this.state.name}
              onChange={this.handleChange}
            />

            <Input
              type="text"
              id="phone"
              name="phone"
              placeholder="Phone number"
              required={true}
              value={this.state.phone}
              onChange={this.handleChange}
            />

            <Input
              type="email"
              id="email"
              name="email"
              placeholder="Email address"
              required={true}
              value={this.state.email}
              onChange={this.handleChange}
            />

            {isAudit && (
              <Input
                type="text"
                id="website"
                name="website"
                placeholder="Website"
                required={true}
                value={this.state.website}
                onChange={this.handleChange}
              />
            )}

            {showMessage && (
              <Textarea
                id="message"
                name="message"
                placeholder="Message"
                rows={4}
                onChange={this.handleChange}
                value={this.state.message}
              />
            )}

            <Box my={4}>
              <Button
                inverted={true}
                type="submit"
                onSubmit={this.handleSubmit}
              >
                {this.state.buttonMessage}
              </Button>
            </Box>
          </Fieldset>
        </Form>
      </Container>
    )
  }
}
